import React, { useEffect, useState } from 'react'

import logo from '../media/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, Grid } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { SubHeader } from '../utils/componentes-utiles/SubHeader';
import { CerrarSesion } from '../utils/componentes-utiles/CerrarSesion';
import { NotificacionFichar } from '../utils/componentes-utiles/NotificacionFichar';

export const Header = () => {

	//global
	//util
	const [tokenusu, setTokenUsu] = useState('');

	//values
	const Navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setTokenUsu(localStorage.getItem('DesRepresentante'));
	}, [location])


	


	return (
		<Grid container xs={12} justifyContent={'space-around'} direction={"row"} alignItems={"center"} className='header' onClick={() => Navigate("/")}>
			<NotificacionFichar></NotificacionFichar>
			<div className='user-logged'>
				{tokenusu ? <AccountCircleIcon sx={{ fontSize: 40 }} /> : ''}&nbsp;<div>{tokenusu}</div>
			</div>
			<img src={logo} className='logo-header' /> Alpha 2.1

			<CerrarSesion></CerrarSesion>
		</Grid>
	)
}
