import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FichajeCard } from './FichajeCard';
import { getFichajesUsuario } from '../../services/apis';
import { SubHeader } from '../../utils/componentes-utiles/SubHeader';
import { mesName } from '../../utils/utils';
import { SelectorMes } from '../../utils/componentes-utiles/SelectorMes';
import { SelectorYear } from '../../utils/componentes-utiles/SelectorYear';

export const FichajesUsuario = () => {

	const [fichajes, setFichajes] = useState([]);
	const [loaded, setLoaded] = useState([]);

	const [nombreMes, setNombreMes] = useState('');
	const [mes, setMes] = useState(new Date().getMonth() + 1);
	const [nombre, setNombre] = useState(" ");
	const [yearSelected, setYearSelected] = useState(new Date().getFullYear());
	const [years, setYears] = useState([]);
	const param = useParams();
	console.log(param.id);




	const fichajesUsuario = async (id) => {
		try {
			const response = await getFichajesUsuario(mes, yearSelected, id);
			setFichajes(response.data.fichajes);
			setNombre(response.data.nombre.nombre);
			setYears(response.data.years);
			console.log(response.data);

			setLoaded(true);
		} catch (error) {
			console.error("Error al añadir el cliente:", error);
		}
	}

	useEffect(() => {
		fichajesUsuario(param.id);
		setNombreMes(mesName(mes))
	}, []);

	useEffect(() => {
		fichajesUsuario(param.id);
		setNombreMes(mesName(mes))
	}, [mes, yearSelected]);


	return (
		<>
			<SubHeader></SubHeader>
			<Grid container xs={12} padding={"1em"} direction={'row'} justifyContent={'center'} alignItems={'center'}>
				<Grid container xs={8} sm={8} md={4} direction={'row'} justifyContent={'start'} alignItems={'center'}>
					<h3>Fichajes de {nombre ? nombre : ""}</h3>
				</Grid>
				<Grid container xs={4} sm={4} md={2} direction={'row'} justifyContent={'center'} alignItems={'center'}>
					{mes && (
						<SelectorMes mes={mes} setMes={setMes} />

					)}
				</Grid>
				<Grid container xs={4} sm={4} md={2} direction={'row'} justifyContent={'center'} alignItems={'center'}>
					{years.length >= 1 && (
						<SelectorYear years={years} setYearSelected={setYearSelected} yearSelected={yearSelected} />
					)}
				</Grid>
				{fichajes.length < 1 && (
					<Grid container xs={12} justifyContent={'center'} alignItems={'center'}>

						<strong>Todabia no hay fichajes</strong>
					</Grid>

				)}
				{fichajes ? fichajes.map((fichaje, index) => (
					<FichajeCard fichaje={fichaje} nombreMes={nombreMes} key={index}></FichajeCard>
				)) : ''}
			</Grid >
		</>
	)
}
