import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { TareaDialog } from '../tareas/TareaDialog';
import { Grid } from '@mui/material';
import { isColorDark } from '../../../utils/utils';
import InfoIcon from '@mui/icons-material/Info';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { TicketDialog } from './TicketDialog';
import { CrearTicket } from './CrearTicket';
import EditIcon from '@mui/icons-material/Edit';


export const TicketCard = ({ ticket, opciones, estados }) => {
    //utils
    const [ticketInfo, setTicketInfo] = useState(ticket);
    const [open, setOpen] = React.useState(false);
    const colaboradeores = (ticket.colaboradores ? ticket.colaboradores.split(', ') : []);
    

    const [backgroundCard, setBackgroundCArd] = useState(estados.find(estado => estado.id === ticket.id_estado).color);
    const [textColor, setTextColor] = useState(isColorDark(ticket.estado_color) ? '#FFFFFF' : '#000000');

    const eliminarTicket = () => {
        alert("hola");
    }

    const iconMapping = {
        PersonAddIcon: <PersonAddIcon />,
        DeleteForeverIcon: <DeleteForeverIcon />,
        InfoIcon: <InfoIcon />,
        LocationOnIcon: <LocationOnIcon />,
        FactCheckIcon: <FactCheckIcon />,
        EditIcon: <EditIcon />
    };

    // console.log(colaboradeores);

    const handleEvent = (opcionId) => {
        switch (opcionId) {
            case "1":
                handleOnChange({ id_estado: 1 })
                console.log(1);

                // Lógica para la opción 1
                break;
            case "2":
                console.log(2);

                handleOnChange({ id_estado: 2 })
                // Lógica para la opción 2
                break;
            case "3":
                console.log(3);
                handleOnChange({ id_estado: 3 })

                // Lógica para la opción 3
                break;
            case "4":
                handleOnChange({ id_estado: 4 })
                console.log(4);
                // Lógica para la opción 4
                break;
            case "90":
                console.log("y yeah",)
                // Lógica para la opción 4
                break;
            default:
                console.log("Lógica para una opción desconocida");
                // Lógica para una opción desconocida
                break;
        }
    }

    const handleOnChange = (data) => {
        // Crear una copia del objeto tarea
        let temp = { ...ticketInfo };

        // Obtener la clave y el valor del objeto data
        const [key, value] = Object.entries(data)[0];

        // Verificar si la clave existe en el objeto tarea
        if (temp.hasOwnProperty(key)) {
            // Cambiar el valor del elemento que coincide
            temp[key] = value;
        }
        // Actualizar el estado de tarea con el nuevo objeto modificado
        // Suponiendo que estás usando useState para manejar el estado de tarea
        setTicketInfo(temp);

        // Para fines de depuración
    };
    useEffect(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            },
            (error) => {
              console.error(`Error Code = ${error.code} - ${error.message}`);
            }
          );
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      }, []);    return (
        <>
            <Grid container xs={12} sm={6} md={4} xl={2} gap={1} direction="row" padding="1em" justifyContent="center" alignItems="center" fontWeight={500}
                className="tarea-card fade-in2"
                style={{
                    backgroundColor: "#" + backgroundCard,
                    color: textColor
                }}
            >
                <Grid container xs={10} justifyContent="center" alignItems="center"
                >
                    <h3>{ticketInfo.descripcion}</h3>
                </Grid>
                <Grid container xs={1} justifyContent="center" alignItems="center" onClick={() => setOpen(true)}
                >
                    <InfoIcon />
                </Grid >

                <Grid container xs={12} gap={1} justifyContent="center" alignItems="start" >
                    <Grid container xs={3} >
                        <strong>AUTOR:</strong>
                        &nbsp;{ticketInfo.autor_nombre}
                    </Grid>
                    <Grid container xs={3} className='miniatura'>
                        <img src={ticket.imagen} alt="Imagen seleccionada" className='miniatura' />
                    </Grid>
                    <Grid container xs={3}  >
                        <strong>Asociados:</strong>{colaboradeores.map((colaborador, index) => (
                            <>
                                {index < 4 && (
                                    <div index={index}>{colaborador} &nbsp;</div>
                                )}
                                {index == 4 && (
                                    <div index={index}>...&nbsp;</div>
                                )}
                            </>
                        ))}
                    </Grid>

                </Grid>

                <Grid container xs={6} justifyContent="center" alignItems="center"
                >
                    <p>Importe: {ticketInfo.importe}{ticketInfo.simbolo}</p>
                </Grid>
                <Grid container xs={12} justifyContent="space-around" alignItems="center"
                >
                    <item>{ticketInfo.estado_nombre}</item>
                    <item>{ticketInfo.fecha}</item>
                    <item>{ticketInfo.minutos}</item>
                </Grid>
                {opciones.map((opcion, index) => (
                    <Grid container xs={3} sm={3}  xl={3} gap={1} justifyContent="space-around" alignItems="center"   key={index}>
                        
                        <Button
                            variant='contained'
                            className='button-default'
                          
                            onClick={() => handleEvent(opcion.id_estado)}
                            disabled={false}
                            startIcon={iconMapping[opcion.icono]}
                        >
                        </Button>
                        
                    </Grid>
                ))}
               
            </Grid>
            {/* //desplegable */}
            <TicketDialog open={open} setOpen={setOpen} ticketInfo={ticketInfo} setTareaInfo={setTicketInfo} opciones={opciones} handleEvent={handleEvent} backgroundCard={backgroundCard} textColor={textColor} />
        </>
    )
}
