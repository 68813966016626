import React, { useEffect, useState } from 'react'
import { getTodosFichajes } from '../../services/apis';
import { SubHeader } from '../../utils/componentes-utiles/SubHeader';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { Box, Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SelectorMes } from '../../utils/componentes-utiles/SelectorMes';
import { mesName } from '../../utils/utils';
import { SelectorYear } from '../../utils/componentes-utiles/SelectorYear';

export const Fichajes = () => {

    const [fichajes, setFichajes] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [mes, setMes] = useState(new Date().getMonth() + 1);
    const [nombreMes, setNombreMes] = useState(mesName(mes));
    const [yearSelected, setYearSelected] = useState(new Date().getFullYear());
    const [years, setYears] = useState([]);


    const dias = ["Empleado", "Horas de este Mes", " "];

    const Navigate = useNavigate();

    const obtenersoloFechaActual = () => {
        const now = new Date(); // Obtiene la fecha y hora actual
        const year = now.getFullYear(); // Obtiene el año
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Obtiene el mes (añade 1 porque los meses van de 0 a 11)
        const day = String(now.getDate()).padStart(2, '0'); // Obtiene el día del mes

        // Devuelve la fecha en el formato "YYYY-MM-DD HH:MM:SS"
        return `${yearSelected}-${month}-${day}`;
    };

    const consultarFichaje = async () => {
        // const fechaActual = obtenersoloFechaActual(); // Obtiene la fecha actual
        try {
            const response = await getTodosFichajes(mes, yearSelected);
            setFichajes(response.data.fichajes);
            setYears(response.data.years)
            console.log(response.data);

            setLoaded(true);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }

    }

    function formatTime(segundos) {
        const horas = Math.floor(segundos / 3600);
        const minutos = Math.floor((segundos % 3600) / 60);
        const seg = segundos % 60;
        return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}:${String(seg).padStart(2, '0')}`;
    }

    useEffect(() => {
        consultarFichaje();
        setNombreMes(mesName(mes));

    }, []);

    useEffect(() => {
        consultarFichaje();
        setNombreMes(mesName(mes));
    }, [mes, yearSelected])
    return (
        <>
            <SubHeader />

            <div className='menu-principal fade-in-2'>
                {!loaded && (<CircularProgress />)}
                {loaded && (
                    <>
                        <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'} xs={12}>
                            <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} xs={11.5} md={6.5}>
                                <Grid container direction={'row'} marginBottom={"0.5em"} alignItems={'center'} justifyContent={'center'} xs={12} gap={1} >
                                    <Grid container xs={12} md={4} direction={'row'} justifyContent={'start'} alignItems={'center'}>
                                        <h3>Fichajes de {nombreMes}</h3>
                                    </Grid>
                                    <Grid container xs={4} sm={4} md={4} direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                        <SelectorMes mes={mes} setMes={setMes} />
                                    </Grid>
                                    <Grid container xs={4} sm={4} md={3} direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                        {years.length >= 1 && (
                                            <SelectorYear years={years} setYearSelected={setYearSelected} yearSelected={yearSelected} />
                                        )}
                                    </Grid>
                                </Grid>
                                {fichajes && fichajes.length >= 1 && (
                                    <TableContainer component={Paper} sx={{ minWidth: "100%" }}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    {dias.map((opcion, key) => (
                                                        <TableCell key={key} align="start">
                                                            {console.log(opcion)}
                                                            <h3>{opcion}</h3>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {fichajes.map((usuario) => (
                                                    <TableRow key={usuario.id} align="start">
                                                        <TableCell><strong>{usuario.usuario}<Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            {usuario.activo && (
                                                                <>
                                                                    <FiberManualRecordIcon sx={{ color: 'green', fontSize: 14 }} />
                                                                    <Typography variant="body2" color="green">Activo</Typography>
                                                                </>
                                                            )}
                                                            {!usuario.activo && (
                                                                <>
                                                                    <FiberManualRecordIcon sx={{ color: 'red', fontSize: 14 }} />
                                                                    <Typography variant="body2" color="red">No Activo</Typography>
                                                                </>
                                                            )}

                                                        </Box></strong></TableCell>
                                                        <TableCell><strong>{formatTime(usuario.sumaFichaje)} </strong></TableCell>
                                                        <TableCell align="center"><strong>{<Button><ArrowCircleRightIcon color='primary' fontSize="large" onClick={() => Navigate("/fichajesjeusuario/" + usuario.id)} /></Button>}</strong></TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Grid>

                        </Grid>

                    </>

                )}
            </div >
        </>
    );
}

