import { Button, Grid } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import React, { useEffect, useState } from 'react'
import { getEstados, getOpcionesTickets, getTickets } from '../../../services/apis';
import { ErrorYRedireccionar } from '../../../utils/componentes-utiles/ErrorYRedireccionar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TicketCard } from './TicketCard';
import { CrearTicket } from './CrearTicket';
import { SubHeader } from '../../../utils/componentes-utiles/SubHeader';

export const Tickets = () => {

    const iconMapping = {
        AddCircleIcon: <AddCircleIcon />

    };
    //utils
    const [loaded, setLoaded] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [opciones, setOpciones] = useState([]);
    const [estados, setEstados] = useState([]);
    const [opcionesTickets, setOpcionesTickets] = useState([]);
    const [crearTicket, setCrearTicket] = useState(false);
    const param = useParams();

    useEffect(() => {
        cargarTickets();
        cargarOpcionesTickes();
    }, [])
    const handleEvent = (opcionId) => {
        switch (opcionId) {
            case "1":
                setCrearTicket(true)

                // Lógica para la opción 1
                break;
            default:
                console.log("Lógica para una opción desconocida");
                // Lógica para una opción desconocida
                break;
        }
    }

    //cargar tickets
    const cargarTickets = async () => {
        try {
            const response = await getTickets(param.id, 1);
            setTickets(response.data.tickets);
            setOpciones(response.data.opcionesTickets);
            cargarEstados();

        } catch (error) {
            console.error("Error cargar tareas", error)
        }
    }
    const cargarOpcionesTickes = async () => {
        try {
            const response = await getOpcionesTickets(param.id, 1);
            setOpcionesTickets(response.data);

        } catch (error) {
            console.error("Error cargar tareas", error)
        }
    }


    const cargarEstados = async () => {
        try {
            const response = await getEstados();
            setEstados(response.data)
            setLoaded(true);

        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    }



    return (
        <div className='fade-in-2'>
            <SubHeader></SubHeader>
            {loaded && (
                <>
                    <h1>{tickets.length > 0 ? 'Tickets Proyecto' : <ErrorYRedireccionar mensaje={'No tienes acceso a este Proyecto'} />}</h1>
                    <div className="horizontal-menu">
                        <Grid container spacing={2} marginBottom={2}>
                            {opcionesTickets.map((opcion, index) => (
                                <Grid container xs={12} gap={1} justifyContent="space-around" alignItems="center" key={opcion.id}>
                                    <Button
                                        variant='contained'
                                        className='button-default'
                                        key={index}
                                        onClick={() => handleEvent(opcion.id)}
                                        disabled={false}
                                        startIcon={iconMapping[opcion.icon]}
                                    >
                                        {opcion.nombre}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    <div className="flex-center">

                        <Grid container gap={2} maxWidth={"90%"} direction="row" justifyContent="center" alignItems="center"  >
                            {tickets.map(ticket => (
                                <TicketCard ticket={ticket} opciones={opciones} estados={estados}   key={ticket.id}/>
                            ))}
                        </Grid>
                    </div>

                </>
            )}
            <CrearTicket open={crearTicket} setOpen={setCrearTicket} />

        </div>

    )
}
