import axios from "axios";
import { getCookie } from "../utils/cookies";
import { AlertError } from "../utils/componentes-utiles/AlertError";


const baseUrl = 'https://www.jcinformatics.com/tessamanagment/WS/'
//gets
export const getopcinesMenu = async () => {
    try {
        const response = await axios.get(baseUrl + 'opcionesmenu.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


export const getProyectos = async () => {
    try {
        const response = await axios.get(baseUrl + 'proyectos.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};


export const getProyecto = async (id) => {
    try {
        const response = await axios.get(baseUrl + 'proyectos.php', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getInfoProyecto = async (id) => {
    try {
        const response = await axios.get(baseUrl + 'proyectos.php', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};



export const getTareas = async (id, tipo, fechaDesde, fechaHasta) => {
    // el tipo es para saber que tipo de opciones hay que cargar
    try {
        const response = await axios.get(baseUrl + 'tareas.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id,
                tipo: tipo,
                fechaDesde: fechaDesde,
                fechaHasta: fechaHasta
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);

        return { error: 407 };
    }
};


export const eliminarTarea = async (id) => {
    try {
        const response = await axios.delete(baseUrl + 'deleteTarea.php', {
            data: {
                token: getCookie('PHPSESSID'),
                data: id
            }

        });
        console.log(id, response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }

}
export const getTarea = async (id, id_tarea, tipo) => {
    try {
        const response = await axios.get(baseUrl + 'tareas.php', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id,
                id_tarea: id_tarea,
                tipo: tipo
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response)
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const postTarea = async (data, archivos) => {
    try {
        // Crear un objeto FormData
        const formData = new FormData();
        formData.append('token', getCookie('PHPSESSID'));
        formData.append('data', JSON.stringify(data)); // Convertir los datos a JSON
        if (archivos) {
            archivos.forEach((archivo) => {
                formData.append('file[]', archivo);
            });
    
        }

        const response = await axios.post(baseUrl + 'postTarea.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Indicar que se usa multipart/form-data
            },
        });

        console.log(response.data);
        if (response.data.success) {
            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al enviar la tarea:', error);
        return null;
    }
};



export const postSA = async (data, archivos) => {
    try {
        const formData = new FormData();
        formData.append('token', getCookie('PHPSESSID'));
        formData.append('data', JSON.stringify(data)); // Convertir los datos a JSON
        if (archivos) {
            archivos.forEach((archivo) => {
                formData.append('file[]', archivo);
            });
    
        }

        const response = await axios.post(baseUrl + 'postSA.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Indicar que se usa multipart/form-data
            },
        });

        console.log(response.data);
        if (response.data.success) {
            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al enviar la tarea:', error);
        return null;
    }

}
export const getTareaSA = async (id, tipo, fechaDesde, fechaHasta) => {
    // el tipo es para saber que tipo de opciones hay que cargar
    try {
        const response = await axios.get(baseUrl + 'tareasSA.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id,
                fechaDesde: fechaDesde,
                fechaHasta: fechaHasta,
                tipo: tipo
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log("sa", response)
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);

        return { error: 407 };
    }
};
export const getEstados = async (id) => {
    try {
        const response = await axios.get(baseUrl + 'estados.php', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
//ticket
export const getTickets = async (id, tipo) => {
    try {
        const response = await axios.get(baseUrl + 'tickets.php', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id,
                tipo: tipo
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}
//trabajadores
export const getTrabajadores = async (tarea) => {
    try {
        const response = await axios.get(baseUrl + 'trabajadores.php', {
            params: {
                token: getCookie('PHPSESSID'),
            },
        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
export const getOpcionesTickets = async (id, tipo) => {
    try {
        const response = await axios.get(baseUrl + 'opcionestickets.php', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id,
                tipo: tipo
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}
//acltualizar tarea
export const actualizarTarea = async (data) => {
    try {
        const response = await axios.put(baseUrl + 'actualizarTarea.php?', {
            token: getCookie('PHPSESSID'),
            data

        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response);

            return response.data;
        } else {
            console.log(response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
export const getPrueba = async () => {
    try {
        const response = await axios.get('http://93.189.90.30/test', {

        });

        if (response) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response;
        } else {
            console.log('Error s');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}


//post
//tareas
//comentarios

export const postComentarioTarea = async (data) => {
    try {
        const response = await axios.post(baseUrl + 'comentariosTareas.php', {
            token: getCookie('PHPSESSID'),
            data

        });
        console.log(response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

//opciones tareas
export const getOpcionesTareas = async (id) => {
    try {
        const response = await axios.get(baseUrl + 'opcionestareas.php?', {
            params: {
                token: getCookie('PHPSESSID'),
                id: id
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}
//update tareas

export const updateTarea = async (tarea) => {
    try {
        const response = await axios.get(baseUrl + 'tareas.php', {
            params: {
                token: getCookie('PHPSESSID'),
                id: tarea
            },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
//tiempo Tareas

//iniciar
export const inicioTarea = async (data) => {
    try {
        const response = await axios.post(baseUrl + 'tiempoTarea.php', {
            token: getCookie('PHPSESSID'),
            data

        });
        console.log(response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
//clientes

export const getClientes = async () => {
    try {
        const response = await axios.get(baseUrl + 'clientes.php', {
            params: {
                token: getCookie('PHPSESSID'),
            },
        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

//fichajes
export const inicioFichaje = async (data) => {
    try {
        const response = await axios.post(baseUrl + 'fichajes.php', {
            token: getCookie('PHPSESSID'),
            data

        });
        console.log(response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getFichaje = async (fecha) => {
    try {
        const response = await axios.get(baseUrl + 'fichajes.php', {
            params: {
                token: getCookie('PHPSESSID'),
                fecha: fecha
            },
        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response.data)
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};
export const finFichaje = async (data) => {
    try {
        const response = await axios.put(baseUrl + 'fichajes.php?', {
            token: getCookie('PHPSESSID'),
            data

        });
        console.log("a", data)
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response);

            return response.data;
        } else {
            console.log(response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);

        return { error: 407 };
    }
};

export const getTodosFichajes = async (mes, year) => {
    try {
        const response = await axios.get(baseUrl + 'allfichajes.php', {
            params: {
                token: getCookie('PHPSESSID'),
                year: year,
                mes: mes
            },
        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response.data)
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getFichajesUsuario = async (mes, year, id) => {
    try {
        const response = await axios.get(baseUrl + 'fichajeUsuario.php', {
            params: {
                token: getCookie('PHPSESSID'),
                mes: mes,
                year: year,
                id: id
            },
        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response.data)
            return response.data;
        } else {
            console.log('Error');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

//recursos humanos
export const getOpcionesRrhh = async () => {
    try {
        const response = await axios.get(baseUrl + 'opcionesrrhh.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

//usuarios
export const getUsuarios = async () => {
    try {
        const response = await axios.get(baseUrl + 'usuarios.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}


export const postUsuario = async (data) => {
    try {
        const response = await axios.post(baseUrl + 'usuario.php', {
            token: getCookie('PHPSESSID'),
            data: data

        });
        console.log(response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            console.log(response.data);
            return response.data;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return error.data;
    }

}

export const delUsuario = async (id) => {
    try {
        const response = await axios.delete(baseUrl + 'usuario.php', {
            data: {
                token: getCookie('PHPSESSID'),
                data: id
            }

        });
        console.log(id, response.data);
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario

            return response.data;
        } else {
            console.log(response.data);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }

}
export const actualizarUsuario = async (data) => {
    try {
        const response = await axios.put(baseUrl + 'usuario.php?', {
            token: getCookie('PHPSESSID'),
            data

        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response);

            return response.data;
        } else {
            return response.data;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

//administrador
export const getOpcionesAdmin = async () => {
    try {
        const response = await axios.get(baseUrl + 'opcionesAdministrador.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}

//roles y permisos
export const getRolesYDepartamentos = async () => {
    try {
        const response = await axios.get(baseUrl + 'rolesydepartamentos.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}

export const actualizarRolesYDepartamentos = async (data, option, rolid) => {
    try {
        const response = await axios.put(baseUrl + 'rolesydepartamentos.php?', {
            token: getCookie('PHPSESSID'),
            option: option,
            rolid:  rolid,
            data

        });
        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            console.log(response);

            return response.data;
        } else {
            console.log(response);
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

//crear ticket
export const getDataCrearTicket = async () => {
    try {
        const response = await axios.get(baseUrl + 'dataCrearTIcket.php', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
}