import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import axios from 'axios';
import { Login } from '../pages/Login';
import { MainMenu } from '../pages/MainMenu';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Proyectos } from '../global/proyectos/Proyectos';
import { RecursosHumanos } from '../global/rrhh/RecursosHumanos';
import { Calendario } from '../global/calendario/Calendario';
import { Admin } from '../global/administrador/Admin';
import { Taller } from '../global/proyectos/taller/Taller';
import { Tareas } from '../global/proyectos/tareas/Tareas';
import { Tickets } from '../global/proyectos/tickets/Tickets';
import { Usuarios } from '../global/administrador/usuarios/Usuarios';
import { Notificaciones } from '../global/administrador/notificaciones/Notificaciones';
import { Header } from '../global/Header';
import { Proyecto } from '../global/proyectos/Proyecto';
import { TareasSolicitudes } from '../global/proyectos/tareas/solicitudes/TareasSolicitudes';
import { Fichajes } from '../global/fichaje/Fichajes';
import { RolesYDepartamentos } from '../global/administrador/permisos/RolesYDepartamentos';
import TicketReader from '../utils/componentes-utiles/useTicketReader';
import { FichajesUsuario } from '../global/fichaje/FichajesUsuario';

// colores de los componentes del MUI
const theme = createTheme({
	palette: {
		primary: {
			main: '#e53935',
		},
		secondary: {
			main: '#f1f8e9',
		},
	},

});


const Router = () => {
	//global
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	return (
		<ThemeProvider theme={theme}>

			<div className='back-main fade-in'>
				<Header />

				<Routes>
					<Route element={<PrivateRoute />}>
						<Route path="/" element={<MainMenu />}></Route>
						{/* //proyectos */}
						<Route path="/proyectos" element={<Proyectos />}></Route>
						<Route path="/proyecto/:id" element={<Proyecto />}></Route>
						{/* //Taller */}
						<Route path="/taller/:id" element={<Taller />}></Route>
						{/* //tareas */}
						<Route path="/tareas/:id" element={<Tareas />}></Route>
						{/* //tickets */}
						<Route path="/tickets/:id" element={<Tickets />}></Route>
						{/* //tareas */}
						<Route path="/solicitudes/:id" element={<TareasSolicitudes />}></Route>
						{/* //recursos humanos */}
						<Route path="/rrhh" element={<RecursosHumanos />}></Route>
						{/* //calendario */}
						<Route path="/calendario" element={<Calendario />}></Route>
						{/* //admin */}
						<Route path="/admin" element={<Admin />}></Route>
						{/* //usuarios */}
						<Route path="/usuarios" element={<Usuarios />}></Route>
						{/* //departamentos */}
						<Route path="/rolesydepartamentos" element={<RolesYDepartamentos />}></Route>
						{/* //notificaciones */}
						<Route path="/notificaciones" element={<Notificaciones />}></Route>
						{/* //fichajes */}
						<Route path="/fichajes" element={<Fichajes />}></Route>
						<Route path="/fichajesjeusuario/:id" element={<FichajesUsuario/>}></Route>

					</Route>
					{/* //global */}
					<Route path="/login?" element={<Login username={username} setUsername={setUsername} password={password} setPassword={setPassword} />}></Route>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</div>
		</ThemeProvider>
	)
}

export default Router