import { useState, useEffect } from 'react';
import Tesseract from 'tesseract.js';

const useTicketReader = (image) => {
  const [status, setStatus] = useState('');
  const [total, setTotal] = useState('');
  const [progress, setProgress] = useState(0); // Estado para el progreso

  // Llamar a Tesseract para procesar la imagen y obtener el total
  useEffect(() => {
    if (image) {
      setStatus('Procesando la imagen...');
      setTotal('');
      setProgress(0); // Reseteamos el progreso a 0

      // Realizar OCR con Tesseract.js
      Tesseract.recognize(image, 'eng', {
        logger: (m) => {
          if (m.status === 'recognizing text') {
            setProgress(Math.round(m.progress * 100)); // Actualizamos el progreso
            setStatus(`Reconociendo texto: ${Math.round(m.progress * 100)}%`);
          }
        },
      })
        .then(({ data: { text } }) => {
          setStatus('Procesamiento completado.');
          console.log('Texto extraído:', text);

          // Buscar el total en el texto extraído
          const totalMatch = text.match(/total[:\s]*([\d,.]+)/i);
          if (totalMatch) {
            setTotal(totalMatch[1]);
          } else {
            setTotal('No se encontró el total en el ticket.');
          }
        })
        .catch((error) => {
          console.error('Error al procesar la imagen:', error);
          setStatus('Error al procesar la imagen.');
        });
    }
  }, [image]); // Este effect solo se ejecutará cuando `image` cambie

  return { status, total, progress };
};

export default useTicketReader;
