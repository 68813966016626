import { Button, Card, CardContent, Grid, Typography, Tooltip, IconButton, Box, CircularProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import React, { useEffect, useState } from 'react';
import { finFichaje, getFichaje, inicioFichaje } from '../../services/apis';
import { useNavigate } from 'react-router-dom';

export const Fichar = () => {
    const [inciado, setIniciado] = useState(false);
    const [segundos, setSegundos] = useState("");
    const [localizacion, setLocalizacion] = useState({});
    const [tiempoTranscurrido, setTiempoTranscurrido] = useState(0);
    const [turnos, setTurnos] = useState([]); // Para almacenar los turnos del día
    const [ultimaEntrada, setUltimaEntrada] = useState(null); // Para mostrar la última entrada o salida
    const [loaded, setLoaded] = useState(false);
    const Navigate = useNavigate();

    const obtenerFechaActual = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const obtenersoloFechaActual = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const finalizarFichaje = async () => {
        try {
            const fechaActual = obtenerFechaActual();
            const response = await finFichaje({ "fecha": fechaActual, "localizacion": localizacion });
            setIniciado(false);
            consultarFichaje(); // Actualizar la vista después de finalizar
        } catch (error) {
            console.error("Error al finalizar fichaje:", error);
            localStorage.clear();
            Navigate("/login");
        }
    };

    const sumarSegundos = (fichajes) => {
        if (Array.isArray(fichajes)) {
            const sumaTotal = fichajes.reduce((acumulador, fichaje) => {
                if (fichaje.hora_fin) {
                    return acumulador + parseInt(fichaje.total, 10);
                } else {
                    const horaActual = new Date();
                    const horaInicio = new Date(fichaje.hora_inicio);
                    const diferenciaSegundos = Math.floor((horaActual - horaInicio) / 1000);
                    acumulador += diferenciaSegundos;
                    setIniciado(true);
                }
                return acumulador;
            }, 0);
            setSegundos(sumaTotal);

            // Establecer el último fichaje
            if (fichajes.length > 0) {
                setUltimaEntrada(fichajes[fichajes.length - 1]);
            }
        }
    };

    const iniciarfichaje = async () => {
        const fechaActual = obtenerFechaActual();
        try {
            const response = await inicioFichaje({ "fecha": fechaActual, "localizacion": localizacion });
            setIniciado(true);
            consultarFichaje(); // Actualizar la vista después de iniciar
        } catch (error) {
            console.error("Error al iniciar el fichaje:", error);
            localStorage.clear();
            Navigate("/login");
        }
    };

    const consultarFichaje = async () => {
        const fechaActual = obtenersoloFechaActual();
        try {
            const response = await getFichaje(fechaActual);
            setTurnos(response.data); // Guardar los turnos completos del día
            sumarSegundos(response.data);
            setLoaded(true);
        } catch (error) {
            console.error("Error al consultar fichajes:", error);
            localStorage.clear();
            Navigate("/login");
        }
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocalizacion({ latitude, longitude });
                },
                (error) => {
                    console.error(`Error Code = ${error.code} - ${error.message}`);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
        consultarFichaje();
    }, []);

    const formatTime = (segundos) => {
        const horas = Math.floor(segundos / 3600);
        const minutos = Math.floor((segundos % 3600) / 60);
        const seg = segundos % 60;
        return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}:${String(seg).padStart(2, '0')}`;
    };

    const formatHour = (dateTime) => {
        const time = new Date(dateTime);
        return `${String(time.getHours()).padStart(2, '0')}:${String(time.getMinutes()).padStart(2, '0')}`;
    };

    const renderTooltipContent = () => (
        <div>
            <Typography variant="body2"><strong>Turnos del día:</strong></Typography>
            {turnos.map((turno, index) => (
                <Typography key={index} variant="body2">
                    {formatHour(turno.hora_inicio)} - {turno.hora_fin ? formatHour(turno.hora_fin) : 'En curso'}
                </Typography>
            ))}
            <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>Jornada Total:</strong> {formatTime(segundos)}
            </Typography>
        </div>
    );

    useEffect(() => {
        let intervalo;
        if (inciado) {
            intervalo = setInterval(() => {
                setTiempoTranscurrido((prev) => prev + 1);
            }, 1000);
        }
        return () => clearInterval(intervalo);
    }, [inciado]);

    return (
        <div className='flex-center'>

            <Grid
                container
                xs={12}
                gap={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="fichaje-card fade-in2"
            >
                <Card
                    sx={{ width: '100%', padding: 2, borderRadius: 3, boxShadow: 3, background: "var(--fondoTarjeta)" }}
                >
                    <CardContent>
                        <>
                            {!loaded && (
                                <CircularProgress size={40} />
                            )}
                            {loaded && (
                                <>
                                    <Grid container direction={'row'} xs={12} justifyContent={'space-between'} >


                                        <Grid container xs={2} justifyContent={'start'}>
                                            <Tooltip title={renderTooltipContent()} placement="bottom-start">
                                                <IconButton>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid container xs={8} justifyContent={'center'}>
                                            <Typography variant="h5" component="div" gutterBottom>
                                                Fichaje
                                            </Typography>
                                        </Grid>
                                        {ultimaEntrada && (
                                            <Grid container xs={2} justifyContent={'end'}>
                                                {
                                                    ultimaEntrada.hora_fin === null && (
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <FiberManualRecordIcon sx={{ color: 'green', fontSize: 14 }} />
                                                            <Typography variant="body2" color="green">Activo</Typography>
                                                        </Box>
                                                    )
                                                }
                                                {ultimaEntrada.hora_fin !== null && (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <FiberManualRecordIcon sx={{ color: 'red', fontSize: 14 }} />
                                                        <Typography variant="body2" color="red"> No Activo</Typography>
                                                    </Box>
                                                )}

                                            </Grid>
                                        )}
                                    </Grid>
                                    <Typography variant="body2" color="text.secondary" marginBottom={2}>
                                        Realiza tu registro de asistencia aquí.
                                    </Typography>
                                    {ultimaEntrada && (
                                        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            Última {ultimaEntrada.hora_fin ? "Salida" : "Entrada"}: {ultimaEntrada.hora_fin ? formatHour(ultimaEntrada.hora_fin) : formatHour(ultimaEntrada.hora_inicio)}
                                            {/* Mostrar indicador "Activo" si está en curso */}
                                        </Typography>
                                    )}

                                    {!inciado ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            endIcon={<PlayCircleOutlineIcon />}
                                            onClick={iniciarfichaje}
                                            sx={{ mt: 2 }}
                                        >
                                            Fichar
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            endIcon={<StopCircleIcon />}
                                            onClick={finalizarFichaje}
                                            sx={{ mt: 2 }}
                                        >
                                            Salida
                                        </Button>
                                    )}
                                </>
                            )}
                        </>
                    </CardContent>

                </Card>
            </Grid>
        </div >
    );
};
